import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const EasyToUseTools = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingTop: { xs: '32px', lg: '60px' },
        paddingLeft: { xs: '16px', lg: '128px' },
        paddingRight: { xs: '16px', lg: '128px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '40px',
          lineHeight: '48px',
          letterSpacing: '-1.125px',
          color: '#000000',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        Easy-to-use tools
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          color: '#787879'
        }}
      >
        Bendable’s CMS was co-designed with librarians to ensure that you would find it easy to use. You can turn features on and off, depending on your community’s needs and your library’s capacity. You’ll receive training for your staff, as well as ongoing technical support and KPIs to keep you updated on Bendable usage in your community.
      </Typography>

    </Box>
  );
};

export default EasyToUseTools;