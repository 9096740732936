import React from 'react';

import Box from '@mui/material/Box';

import ManufacturingCareerCard from '../../images/career-collection-card-manufacturing.png';

const CollectionExamplesBG = (props) => {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: { xs: '40px', lg: '60px' },
        paddingBottom: { xs: '40px', lg: '60px' },
        // width: '100%',
        // height: { xs: '100%', lg: '100%' },
        // background: `url(https://bendable.s3.us-west-1.amazonaws.com/mg_libraries_initial_set_of_collections.jpg) no-repeat center center`,
        // backgroundSize: 'cover',
      }}
    >
      <img src={ManufacturingCareerCard} width="50%" />
    </Box>
  );
};

export default CollectionExamplesBG;