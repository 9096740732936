import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InitialSetOfCollections = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingLeft: { xs: '16px', lg: '128px' },
        paddingRight: { xs: '16px', lg: '128px' },
        // marginTop: { xs: '130px', lg: '0px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '40px',
          lineHeight: '48px',
          letterSpacing: '-1.125px',
          color: '#000000',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        An initial set of Bendable Collections
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          color: '#787879',
          marginBottom: '16px'
        }}
      >
        We know that many libraries are playing a growing role in local workforce development. If that’s you, Bendable has got your back. We’ll use local labor market data to make sure your patrons get access to the right Career Collections—learning pathways aimed at getting started in in-demand industries in your community.
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          color: '#787879'
        }}
      >
        We’ve found that when it comes to learning, people trust their neighbors more than algorithms. So we’ve designed a way for you to showcase what your community knows and is passionate about. With our Bendable Plus option, our team will help to create Community Collections—personal learning playlists on specific topics, put together by local residents. And we’ll show your staff how to make more.
      </Typography>

    </Box>
  );
};

export default InitialSetOfCollections;