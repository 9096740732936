import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import UniverseOfLearning from '../components/librariesPage/universeOfLearning';
import PhonesWithBendable from '../components/librariesPage/phonesWithBendable';

import Footer from '../components/footer/Footer';
import CtaButton from '../components/librariesPage/ctaButton';
import PlaceToLearn from '../components/librariesPage/placeToLearn';
import YourOwnLearningCatalog from '../components/librariesPage/yourOwnCatalog';
import WhatDoYouGet from '../components/librariesPage/whatDoYouGet';
import CollectionExamplesBG from '../components/librariesPage/collectionExamplesBG';
import InitialSetOfCollections from '../components/librariesPage/initialSetOfCollections';
import StaffPicksAndSpotlight from '../components/librariesPage/staffPicksAndSpotlight';
import SpotlightRecommendations from '../components/librariesPage/spotlightRecommendations';
import ResourceWizard from '../components/librariesPage/resourceWizard';
import EasyToUseTools from '../components/librariesPage/easyToUseTools';
import ResultsThatSpeak from '../components/librariesPage/resultsThatSpeak';
import DownloadButton from '../components/librariesPage/downloadButton';
import Faq from '../components/librariesPage/faq';
import ContactForm from '../components/librariesPage/contactForm';
import ContactFormImageBG from '../components/librariesPage/contactFormImageBG';
import BendableHeroBG from '../components/librariesPage/bendableHeroBG';

const LibrariesPage = (props) => {

  const handleScheduleConversation = () => {
    console.log('libraries.handleScheduleConversation() called...');
    setTimeout(() => {
      const el = document.getElementById('contactFormWrapper');
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  };

  const handleDownloadBrochure = () => {
    console.log('libraries.handleDownloadBrochure() called...');

  };

  return (
    <Layout pageTitle="Bendable for Libraries">
      <PrimaryAppBar instanceName="" backPath="/" />

      {/* ROW 1 */}
      <Grid
        container
        direction={{ xs: 'column-reverse', lg: 'row'}}
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
          // alignItems: 'center',
          marginTop: { xs: '57px' , lg: '94px' }, 
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
          sx={{ overflow: 'hidden' }}
        >
          <UniverseOfLearning onCta={handleScheduleConversation} />
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <BendableHeroBG />
        </Grid>
      </Grid>

      {/* ROW 2 */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: { xs: 'column-reverse' , lg: 'row' },
          width: '100%',
          height: '100%',
          background: '#ffffff',
          // alignItems: 'center', 
        }}
      >
        <PhonesWithBendable />
      </Box>

      {/* HEADING */}
      <WhatDoYouGet />

      {/* ROW 3 */}
      <Grid
        container
        direction={{ xs: 'column', lg: 'row'}}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <PlaceToLearn />
        </Grid>        
        
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <YourOwnLearningCatalog />
        </Grid>
      </Grid>


      {/* ROW 4 */}
      <Grid
        container
        direction={{ xs: 'column-reverse', lg: 'row'}}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <InitialSetOfCollections />
        </Grid>        
        
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <CollectionExamplesBG />
        </Grid>
      </Grid>


      {/* ROW 5 */}
      <Grid
        container
        direction={{ xs: 'column', lg: 'row'}}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <StaffPicksAndSpotlight />
        </Grid>        
        
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <SpotlightRecommendations />
        </Grid>
      </Grid>


      {/* ROW 6 */}
      <Grid
        container
        direction={{ xs: 'column-reverse', lg: 'row'}}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <EasyToUseTools />
        </Grid>        
        
        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <ResourceWizard />
        </Grid>
      </Grid>

      {/* ROW 7 */}
      <Box
        sx={{
          marginTop: { xs: '30px', lg: '30px'},
          marginBottom: { xs: '30px', lg: '80px'}
        }}
      >
        <CtaButton text="See it for yourself" backgroundColor="#F4615F" onClick={handleScheduleConversation} />
      </Box>

      {/* ROW 8 */}
      <ResultsThatSpeak onCta={handleScheduleConversation} />

      {/* ROW 9 */}
      <Faq />

      {/* ROW 10 */}
      {/* <Box
        sx={{
          padding: '10px',
          marginTop: { xs: '60px', lg: '180px'},
          marginBottom: { xs: '60px', lg: '180px'}
        }}
      >
        <DownloadButton onClick={handleDownloadBrochure} />
      </Box> */}

      {/* ROW 11 */}
      <Grid
        container
        direction={{ xs: 'column', lg: 'row'}}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
          sx={{
            height: { xs: '250px', lg: '750px'},
            width: '100%'
          }}
        >
          <ContactFormImageBG />
        </Grid>        
        
        <Grid
          item
          id="contactFormWrapper" 
          xs={12} 
          sm={6}
          sx={{
            width: '100%',
            height: { xs: '100%', lg: '750px'},
            overflow: 'hidden'
          }}
        >
          <ContactForm showTopLine={true} />
        </Grid>
      </Grid>

      <Footer />
    </Layout>
  )
}

export default LibrariesPage;