import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';

const PlaceToLearn = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
      }}
    >
      <StaticImage src="../../images/placeCommunityComesToLearn.png"  alt="" />

    </Box>
  );
};

export default PlaceToLearn;