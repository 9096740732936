import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CtaButton from './ctaButton';
import { Stack } from '@mui/material';

const ResultsThatSpeak = (props) => {
  const { onCta } = props;

  const handleScheduleConversation = () => {
    onCta();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        background: '#1B7392',
        paddingTop: { xs:'61px', lg: '83px' },
        paddingBottom: { xs:'43px', lg: '80px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
        alignItems: 'center'
      }}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs:'100%', lg: '70%' },
          height: '100%',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >

          <Typography
            sx={{
              fontFamily: 'Cooper Std',
              fontStyle: 'normal',
              fontWeight: 900,
              fontSize: { xs:'35px', lg: '54px' },
              lineHeight: { xs:'40px', lg: '60px' },
              letterSpacing: '-1.125px',
              color: '#E9AD21',
              width: '100%',
              paddingBottom: '21px',
            }}
          >
            Results that speak for themselves
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 800,
              fontSize: { xs:'90px', lg: '150px' },
              lineHeight: { xs:'95px', lg: '200px' },
              letterSpacing: '-4.6px',
              color: '#FFFFFF',
              paddingBottom: '21px',
            }}
          >
            5x
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs:'35px', lg: '45px' },
              lineHeight: { xs:'40px', lg: '65px' },
              color: '#FFFFFF',
              paddingBottom: '32px',
            }}
          >
            engagement with learning content on Bendable versus other platforms
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '18px',
              lineHeight: '35px',
              letterSpacing: '-1.125px',
              color: '#95FFEF',
              paddingBottom: '61px',
            }}
          >
            Source: St. Joe County Public Library pilot program, South Bend, Indiana
          </Typography>

          <CtaButton text="Schedule a conversation" backgroundColor="#E9AD21" onClick={handleScheduleConversation} />

      </Box>

    </Box>
  );
};

export default ResultsThatSpeak;