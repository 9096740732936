import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CtaButton from './ctaButton';

const UniverseOfLearning = (props) => {
  const { onCta } = props;

  const handleScheduleConversation = () => {
    onCta();
  };

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        // height: '100%',
        // flexGrow: 1,
        background: '#E9AD21',
        paddingTop: { xs:'31px', lg: '129px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'35px', lg: '54px' },
          lineHeight: { xs:'40px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'220px', sm: '500px', lg: '500px', xl: '750px' },
          paddingBottom: '21px',
        }}
      >
        A universe of&nbsp;learning, customized by&nbsp;you
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          letterSpacing: '-1.125px',
          color: '#5F6468',
          // width: { xs:'310px', lg: '483px', xl: '600px' },
          paddingBottom: '61px',
        }}
      >
        Bendable delivers online and in-person learning to your patrons via a beautifully designed browser-based app. Think of Bendable as your digital end cap for learning—a way to make content discovery fun, relevant and timely. Whether it’s a yoga class at your local YMCA or an online course on coding from a leading national provider, Bendable is the place your patrons can tap into the learning they need.
      </Typography>

      <CtaButton text="Schedule a conversation" backgroundColor="#1B7392" onClick={handleScheduleConversation} />

    </Box>
  );
};

export default UniverseOfLearning;