import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const YourOwnLearningCatalog = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingLeft: { xs: '16px', lg: '128px' },
        paddingRight: { xs: '16px', lg: '128px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '40px',
          lineHeight: '48px',
          letterSpacing: '-1.125px',
          color: '#000000',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        Your own Bendable learning catalog
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          color: '#787879'
        }}
      >
        Our team of learning specialists will craft a catalog for your patrons out of the tens of thousands of digital courses, classes and resources that they now have access to—but are likely buried somewhere on your website. We balance topics, skill level, age and modality so that all of your patrons are one click away from the learning they need. We’ll also highlight great learning opportunities right in your own community, such as through the city's parks department, your neighborhood senior center or Goodwill, so that local choices are offered alongside the big national providers.
      </Typography>

    </Box>
  );
};

export default YourOwnLearningCatalog;