import React, { useState } from 'react';
import { DateTime } from "luxon";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #E9AD21`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  height: '100px',
  backgroundColor: '#ffffff',
  flexDirection: 'row',
  textAlign: 'left',
  paddingLeft: '16px',
  paddingRight: '16px',
  [theme.breakpoints.up("sm")]: {
    paddingLeft: '44px',
    paddingRight: '52px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg) scale(2)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg) scale(2)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: '16px',
  paddingRight: '16px',
  [theme.breakpoints.up("sm")]: {
    paddingLeft: '52px',
    paddingRight: '52px',
  },
  borderTop: '1px solid #E9AD21',
}));


const Faq = (props) => {

  const [expanded, setExpanded] = useState('');
  const now = DateTime.now();
  const monthAndYear = now.toFormat('MMMM yyyy');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingTop: { xs: '32px', lg: '144px' },
        backgroundColor: '#E9AD21',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >

      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs: '30px', lg: '54px' },
          lineHeight: { xs: '30px', lg: '55px' },
          color: '#1B7392',
          paddingBottom: { xs: '20px', lg: '84px' },
        }}
      >
        FAQs
      </Typography>

      <Box
        sx={{
          paddingLeft: { xs: '16px', lg: '162px' },
          paddingRight: { xs: '16px', lg: '162px' },
          paddingBottom: { xs: '16px', lg: '220px' },
          width: '100%'
        }}
      >
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              Who is behind Bendable?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '16px', fontFamily: 'Sharp Sans' }}>
              Bendable was developed by the Drucker Institute, a nonprofit social enterprise based at Claremont Graduate 
              University. The Institute launched Bendable in June 2020 in South Bend, Indiana, in partnership with the St. 
              Joe County Public Library and with philanthropic funding from Google’s and Walmart’s corporate foundations. 
              The system was designed by IDEO. In late 2022, the Institute team that created Bendable was given the 
              opportunity to spin it out on their own and operate it independently. Their company, Bendable Labs, now runs 
              the platform.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              What’s it cost?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '4px', fontFamily: 'Sharp Sans' }}>
              It depends. Bendable pricing is based on the size of a library’s service area and the desired level of customization. To receive a quote, schedule a conversation below with a Baker & Taylor representative.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              What are a library’s staffing requirements to set up and maintain Bendable?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '16px', fontFamily: 'Sharp Sans' }}>
              Setting up Bendable requires only a few hours of library staff time. And no library IT personnel is needed to maintain the platform. After being trained, a library may choose to actively curate its Bendable instance. But there is also an option to “set it and forget it,” with the site automatically refreshing the learning content that is highlighted.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              How does Bendable integrate with a library’s existing ILS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '16px', fontFamily: 'Sharp Sans' }}>
              No integration with the library’s existing network is required to deploy Bendable. That said, 
              we can integrate with your ILS for one-time verification of library card status.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              What is Bendable’s privacy policy?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '16px', fontFamily: 'Sharp Sans' }}>
              Bendable is compliant with the California Privacy Rights Act (CPRA). You can find our full policy <a href="https://network.bendable.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>here</a>.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography sx={{ fontSize: { xs: '18px', lg: '26px' }, lineHeight: '30px', fontFamily: 'Sharp Sans' }}>
              Who is included in Bendable’s partner provider list?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ textAlign: 'left', paddingTop: '16px', paddingBottom: '16px', fontFamily: 'Sharp Sans' }}>
              As of {monthAndYear}, Bendable’s master learning catalog included these trusted content partners: Brainfuse, Cell-Ed, Common Threads, Coursera, Creativebug, DigitalLearn, edX, GCFGlobal, GetSetUp, Khan Academy, LearningExpress, LinkedIn Learning, Mango Languages, ParentTV, Penn Foster, Pronunciator, SCORE, Skillshare, Sokanu, Study.com,  Universal Class and WorkForge.
            </Typography>
          </AccordionDetails>
        </Accordion>

      </Box>

    </Box>
  );
};

export default Faq;