import React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';

import SpotlightSample from '../../images/spotlight-on-fostering-creativity.png';

const StaffPicksAndSpotlight = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingTop: { xs: '80px', lg: '60px' },
        paddingBottom: { xs: '60px', lg: '40px' },
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={SpotlightSample}  alt="" width="75%" />

    </Box>
  );
};

export default StaffPicksAndSpotlight;