import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SpotlightRecommendations = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        paddingTop: { xs: '32px', lg: '60px' },
        paddingLeft: { xs: '16px', lg: '128px' },
        paddingRight: { xs: '16px', lg: '128px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs: '32px', sm: '40px', lg: '40px' },
          lineHeight: { xs: '40px', sm: '48px', lg: '48px' },
          letterSpacing: '-1.125px',
          color: '#000000',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        Spotlights
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '30px',
          color: '#787879'
        }}
      >
        Bendable gives your staff easy ways to feature select content so that your Bendable homepage is always fresh and timely. If time is tight, you can choose from our menu of pre-set Bendable Spotlights on everything from “Back to school” to “Staying fit and healthy” to “Celebrating Black history.”
      </Typography>

    </Box>
  );
};

export default SpotlightRecommendations;