import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const WhatDoYouGet = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        marginTop: { xs: '72px', lg: '106px' },
        paddingLeft: '80px',
        paddingRight: '80px',

      }}
    >

      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs: '36px', lg: '54px' },
          lineHeight: { xs: '36px', lg: '55px' },
          textAlign: 'center',
          color: '#38718F',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        What&nbsp;do you&nbsp;get?
      </Typography>

    </Box>
  );
};

export default WhatDoYouGet;